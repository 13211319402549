<template>
  <component :is="component">
    <slot />
  </component>
</template>

<script>
import VuebaseLaylout from "./layouts/VuebaseLayout";

export default {
  components: { VuebaseLaylout },
  name: 'App',
  metaInfo: {
    title: "App",
    titleTemplate: "%s | CAR BOOKING SITE",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },

  data: () => ({
    //
  }),

  computed: {
    component() {
      return VuebaseLaylout;
    },

  }


};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

body {
  font-family: 'Poppins', sans-serif;
}

.v-main {
  background-color: #f5f5f5;
}
</style>
