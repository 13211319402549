import Vue from 'vue'
import VueRouter from 'vue-router'

import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '@/firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
  },

  {
    path: '/booking',
    name: 'booking',
    component: () => import('../views/BookingView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/card',
    name: 'card',
    component: () => import('../views/CardView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/Mreport',
    name: 'Mreport',
    component: () => import('../reports/MainReport.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('../reports/EmergencyReport.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reportNumberOfTrips',
    name: 'reportNumberOfTrips',
    component: () => import('../reports/NumberOfTrips.vue'),
    meta: { requiresAuth: true },
  },


 
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  onAuthStateChanged(auth, (user) => {   
   
    if (to.matched.some(record => record.meta.requiresAuth) && !user) {

      next('/login')
      return;
    }
   
    next();
  })

})

export default router
